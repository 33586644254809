<script setup>
import Checkbox from "@/Components/Checkbox.vue";
import GuestLayout from "@/Layouts/GuestLayout.vue";
import InputError from "@/Components/InputError.vue";
import InputLabel from "@/Components/InputLabel.vue";
import PrimaryButton from "@/Components/PrimaryButton.vue";
import TextInput from "@/Components/TextInput.vue";
import { Head, Link, useForm } from "@inertiajs/vue3";
import AutheticationIllustration from "@/Components/AuntheticationIllustration.vue";

defineProps({
  canResetPassword: Boolean,
  status: String,
  warning: String,
});

const form = useForm({
  email: "",
  password: "",
  remember: false,
});

const submit = () => {
  form.post(route("login"), {
    onFinish: () => form.reset("password"),
  });
};
</script>

<template>
  <div>
    <GuestLayout>
      <Head :title="lang().label.login" />

      <div class="d-flex flex-column flex-column-fluid flex-lg-row">
        <!--begin::Aside-->
        <div class="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">
          <!--begin::Aside-->
          <div class="d-flex flex-center flex-lg-start flex-column">
            <!--begin::Logo-->
            <Link :href="route('dashboard')" class="mb-7">
              <img
                alt="Logo"
                src="\images\w-logo.png"
                style="max-width: 320px"
              />
            </Link>
            <!--end::Logo-->
            <!--begin::Title-->
            <!--end::Title-->
          </div>
          <!--begin::Aside-->
        </div>
        <!--begin::Aside-->
        <!--begin::Body-->
        <div
          class="login-page d-flex flex-column-fluid flex-lg-row-auto p-12 p-lg-20"
          style="margin: 0 auto"
        >
          <!--begin::Card-->
          <div
            class="login-form bg-body d-flex flex-column align-items-stretch flex-center rounded-4 w-md-400px h-md-500px p-10"
          >
            <!--begin::Wrapper-->
            <div class="d-flex flex-center flex-column flex-column-fluid">
              <!--begin::Form-->
              <form
                @submit.prevent="submit"
                class="form w-100"
                novalidate="novalidate"
              >
                <!--begin::Heading-->
                <div class="text-center mb-11">
                  <!--begin::Title-->
                  <h1 class="text-dark fw-bolder mb-3">Sign In</h1>
                  <!--end::Title-->
                  <!--begin::Subtitle-->
                  <div class="text-gray-500 fw-semibold fs-6">Bileta</div>
                  <!--end::Subtitle=-->
                </div>
                <!--begin::Heading-->
                <p v-if="status" class="text-success">
                  {{ status }}
                </p>
                <p v-if="warning" class="text-danger">
                  {{ warning }}
                </p>
                <!--begin::Input group=-->
                <div class="fv-row mb-5">
                  <!--begin::Email-->
                  <InputLabel
                    for="email"
                    :value="lang().label.email"
                    class="sr-only"
                  />
                  <TextInput
                    id="email"
                    type="email"
                    v-model="form.email"
                    required
                    autofocus
                    class="form-control bg-transparent"
                    autocomplete="username"
                    :placeholder="lang().label.email"
                    :error="form.errors.email"
                  />
                  <InputError class="mt-2" :message="form.errors.email" />
                  <!--end::Email-->
                </div>
                <!--end::Input group=-->
                <div class="fv-row mb-3">
                  <!--begin::Password-->
                  <InputLabel
                    for="password"
                    :value="lang().label.password"
                    class="sr-only"
                  />
                  <TextInput
                    id="password"
                    type="password"
                    v-model="form.password"
                    required
                    autocomplete="current-password"
                    :placeholder="lang().placeholder.password"
                    :error="form.errors.password"
                    class="form-control bg-transparent"
                  />
                  <InputError class="mt-2" :message="form.errors.password" />

                  <!--end::Password-->
                </div>
                <!--end::Input group=-->
                <!--begin::Wrapper-->
                <div
                  class="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8"
                >
                  <div></div>
                  <!--begin::Link-->
                  <!--begin::Link-->
                  <Link
                    v-if="canResetPassword"
                    :href="route('password.request')"
                    class="link-primary"
                  >
                    {{ lang().label.lost_password }}
                  </Link>
                  <!--end::Link-->
                  <!--end::Link-->
                </div>
                <!--end::Wrapper-->
                <!--begin::Submit button-->
                <div class="d-grid mb-10">
                  <PrimaryButton
                    class="btn btn-primary"
                    :class="{ 'opacity-25': form.processing }"
                    :disabled="form.processing"
                  >
                    {{
                      form.processing
                        ? lang().button.login + "..."
                        : lang().button.login
                    }}
                  </PrimaryButton>
                </div>
                <!--end::Submit button-->
                <!--begin::Sign up-->
                <!--end::Sign up-->
              </form>
              <!--end::Form-->
            </div>
            <!--end::Wrapper-->
          </div>
          <!--end::Card-->
        </div>
        <!--end::Body-->
      </div>
    </GuestLayout>
  </div>
</template>
